class Driver {
  constructor(Service, path, path2, Util) {
    this.httpService = Service;
    this.path = path;
    this.path2 = path2;
    this.Util = Util;
  }

  getDriverList = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 +
      this.Util.queryParamsFromObj(this.path.GET_DRIVER_LIST, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getPilots = (data) => {
    let getData = data && data ? `/owner/myDriverList?skip=${data.skip}&limit=${data.limit}` :`/owner/myDriverList` 
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + getData;
      // this.Util.queryParamsFromObj(this.path2.DRIVER + "/getDrivers", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getPilotTripsheets = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path2.DRIVER + "/getDriverTripsheets",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addPilot = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/addDriver"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url,data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addProfilepic = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/DriverProfilePicUpload"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url,data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addDriverlicense = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/DriverIdUpload"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url,data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getPilot =(data)=>{
    const url =  process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/owner/myDriverDetails?driverId=${data}`
  return new Promise((resolve, reject) => {
    this.httpService
      .get(url)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
  }
  updatePilot =(data) =>{
    const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/updatePilot"
    return new Promise((resolve, reject) => {
    this.httpService
      .patch(url,data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
  }

  searchPilot =(data)=>{
    const url =  process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/owner/myDriverList?search=${data}`
  return new Promise((resolve, reject) => {
    this.httpService
      .get(url)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
  }

  addDriverBanckFiles = (data,id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `owner/${id}/drivenerBankFiles`
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url,data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getPilotPrintDetails =(id)=>{
    const url =  process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/owner/${id}/pilotPreview`
  return new Promise((resolve, reject) => {
    this.httpService
      .get(url)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
  }
}


export default Driver;
