// import React, { Component, Suspense } from "react";
// import { Switch, Route, BrowserRouter } from "react-router-dom";
// import { Signin } from "./pages/index";
// import { routes } from "./navigation/index";
// import { Loader } from "./components/index";
// import Services from "./services/index";
// import Session from "./session/index";
// import apolloClient from "./apolloSetup";
// import { ApolloProvider } from "@apollo/client";
// import "./App.css";
// const Service = new Services();
// global.service = Service;
// const session = new Session();
// global.session = session;

// class App extends Component {
//   render() {
//     const menu = routes.map((route, index) => {
//       return route.component && global.session.get("BearerToken") ? (
//         <Route
//           key={index}
//           path={route.path}
//           exact={route.exact}
//           name={route.name}
//           render={(props) => <route.component {...props} />}
//         />
//       ) : <Route path="/" component={Signin} />;
//     });
//     return (
//       <BrowserRouter>
//         <ApolloProvider client={apolloClient}>
//           <Suspense fallback={<Loader />}>
//             <Switch>
//               {menu}
//             </Switch>
//           </Suspense>
//         </ApolloProvider>
//       </BrowserRouter>
//     );
//   }
// }

// export default App;

// import React, {Component, useEffect, Suspense } from "react";
// import { Switch, Route, BrowserRouter } from "react-router-dom";
// import { Signin } from "./pages/index";
// import { routes } from "./navigation/index";
// import { Loader } from "./components/index";
// import Services from "./services/index";
// import Session from "./session/index";
// import apolloClient from "./apolloSetup";
// import { ApolloProvider } from "@apollo/client";
// import { generateToken, messaging } from "./firebase";
// import { onMessage } from "firebase/messaging";
// import toast from "react-hot-toast";
// import "./App.css";
// import { RegistrationForm } from "./register.js/registration";
// import { ForSuccessMessage, OtpVerification } from "./verification.js/verification";
// import { ConfirmPassword, ForgotPassword } from "./forgotPassword.js/forgotpassword";
// const Service = new Services();
// global.service = Service;
// const session = new Session();
// global.session = session;

// class App extends Component {
  
//   render() {
//     const menu = routes.map((route, index) => {
//       return route.component && global.session.get("BearerToken") ? (
//         <Route
//           key={index}
//           path={route.path}
//           exact={route.exact}
//           name={route.name}
//           render={(props) => <route.component {...props} />}
//         />
//       ) : (global.window.location.pathname == "/confirm-password" || global.window.location.pathname == "/registration" ||  global.window.location.pathname == "/verification" || global.window.location.pathname == "/success") || global.window.location.pathname == "/forgot-password" ? ((global.window.location.pathname == "/registration"
//        &&  <Route path={"/registration"} component={RegistrationForm} />) ||  (global.window.location.pathname == "/verification" &&  <Route path={"/verification"} component={OtpVerification} />) ||  (global.window.location.pathname == "/confirm-password" &&  <Route path={"/confirm-password"} component={ConfirmPassword} />)
//       || (global.window.location.pathname == "/success" &&  <Route path={"/success"} component={ForSuccessMessage} /> ||  global.window.location.pathname == "/forgot-password"  &&  <Route path={"/forgot-password"} component={ForgotPassword} />))  :  <Route path="/" component={Signin} />;
//      });
//     return (
//       <BrowserRouter>
//         <ApolloProvider client={apolloClient}>
//           <Suspense fallback={<Loader />}>
//             <Switch>
//               {menu}
//             </Switch>
//           </Suspense>
//         </ApolloProvider>
//       </BrowserRouter>
//     );
//   }
// }

// export default App;


// import React, { Component, Suspense } from "react";
// import { Switch, Route, BrowserRouter } from "react-router-dom";
// import { Signin } from "./pages/index";
// import { routes } from "./navigation/index";
// import { Loader } from "./components/index";
// import Services from "./services/index";
// import Session from "./session/index";
// import apolloClient from "./apolloSetup";
// import { ApolloProvider } from "@apollo/client";
// import "./App.css";
// const Service = new Services();
// global.service = Service;
// const session = new Session();
// global.session = session;

// class App extends Component {
//   render() {
//     const menu = routes.map((route, index) => {
//       return route.component && global.session.get("BearerToken") ? (
//         <Route
//           key={index}
//           path={route.path}
//           exact={route.exact}
//           name={route.name}
//           render={(props) => <route.component {...props} />}
//         />
//       ) : <Route path="/" component={Signin} />;
//     });
//     return (
//       <BrowserRouter>
//         <ApolloProvider client={apolloClient}>
//           <Suspense fallback={<Loader />}>
//             <Switch>
//               {menu}
//             </Switch>
//           </Suspense>
//         </ApolloProvider>
//       </BrowserRouter>
//     );
//   }
// }

// export default App;

import React, { useEffect, Suspense } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { Signin } from "./pages/index";
import { routes } from "./navigation/index";
import { Loader } from "./components/index";
import Services from "./services/index";
import Session from "./session/index";
import apolloClient from "./apolloSetup";
import { ApolloProvider } from "@apollo/client";
import { generateToken, messaging } from "./firebase";
import { onMessage } from "firebase/messaging";
import toast from "react-hot-toast";
import "./App.css";
import { RegistrationForm } from "./register.js/registration";
import { ForSuccessMessage, OtpVerification } from "./verification.js/verification";
import { ConfirmPassword, ForgotPassword } from "./forgotPassword.js/forgotpassword";
const Service = new Services();
global.service = Service;
const session = new Session();
global.session = session;

const App = () => {
  useEffect(() => {
    generateToken();
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log(payload);
      // toast.success(payload?.notification?.body);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const menu = routes.map((route, index) => {
    return route?.component && global?.session.get("BearerToken") ? (
      <Route
        key={index}
        path={route?.path}
        exact={route?.exact}
        name={route?.name}
        render={(props) => <route.component {...props} />}
      />
      
    ) : 
    // (
    //   <Route key={index} path="/" component={Signin} />
    // );
    (global.window.location.pathname == "/confirm-password" || global.window.location.pathname == "/registration" ||  global.window.location.pathname == "/verification" || global.window.location.pathname == "/success") || global.window.location.pathname == "/forgot-password" ? ((global.window.location.pathname == "/registration"
      &&  <Route path={"/registration"} component={RegistrationForm} />) ||  (global.window.location.pathname == "/verification" &&  <Route path={"/verification"} component={OtpVerification} />) ||  (global.window.location.pathname == "/confirm-password" &&  <Route path={"/confirm-password"} component={ConfirmPassword} />)
     || (global.window.location.pathname == "/success" &&  <Route path={"/success"} component={ForSuccessMessage} /> ||  global.window.location.pathname == "/forgot-password"  &&  <Route path={"/forgot-password"} component={ForgotPassword} />))  :  <Route path="/" component={Signin} />;
    })
  

  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <Suspense fallback={<Loader />}>
          <Switch>
            {menu}
            {/* Redirect to Signin page if no route matches */}
            {/* <Route component={Signin} /> */}
          </Switch>
        </Suspense>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;


