import {
  Box,
  Button,
  Grid,
  Input,
  Paper,
  Stack,
  TableCell,
  Typography,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CardContent from "@mui/material/CardContent";
import * as CONFIG from "../../config/GlobalConstants";
import * as Fields from "../../sharedComponents";
import { Card, FormHelperText, FormLabel } from "@material-ui/core";
import Header from "../../components/header";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import "../../common.css";
import _ from "lodash";
import Util from "../../utils";
import { number } from "prop-types";
import * as Components from "../../sharedComponents";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ValidateFields from "../../validations/validateFields";
import CryptoJS from 'crypto-js';

const useStyles = makeStyles((theme) => ({
  img_style: {
    width: "250px",
    height: "107px",
    display: "block",
    margin: "auto",
  },
  paper_style: {
    display: "flex",
    padding: 20,
    width: 600,
    margin: "20px auto",
    borderRadius: 10,
    flexWrap: "wrap",
    height: "80vh",
    overflowY: "auto",
  },
  alertBox: {
    padding: "0px",
  },
  forgot_password: {
    fontSize: "10pt",
    color: "#366E93",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "#366E93",
    color: "#FFFFFF",
    textTransform: "none",
  },
  registernow: {
    fontSize: "10pt",
    color: "#366E93",
    fontWeight: "bold",
  },
  account: {
    fontSize: "10pt",
    color: "#777777",
  },
  bottom_fields: {
    textAlign: "center",
    marginTop: "5%",
    padding: "10px",
  },
  header_buttons: {
    display: "flex",
    padding: "0px 15px",
  },
  alertBox: {
    padding: "10px 20px",
  },
  vendor_info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  vendor_name_mbno: {
    alignSelf: "center",
    padding: "0px 20px",
    display: "flex",
    flexDirection: "column",
    color: "#484848",
    borderLeft: "3px solid #DEDEDE",
  },
  vendor_details: {
    color: "#366E93",
    marginRight: "15px",
    cursor: "pointer",
    alignSelf: "center",
  },
  tabs_box: {
    padding: "10px 40px 10px 24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  indicator: {
    height: "4px !important",
    background: "linear-gradient(85deg, #F3644F 70%, #F3644F 90%)",
  },
  create_voucher_button: {
    padding: "9px 15px",
    textTransform: "none",
    background: "#649B42",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#649B42",
      color: "#FFF",
    },
  },
  voucher_button: {
    padding: "9px 15px",
    textTransform: "none",
    backgroundColor: "#366E93",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#366E93",
      color: "#FFF",
    },
  },
  export_button: {
    marginLeft: "15px",
    padding: "8px 15px",
    background: "#366E93",
    textTransform: "none",
    color: "#FFF",
    "&:hover": {
      background: "#366E93",
    },
  },
  fleet_btn: {
    textTransform: "none",
    backgroundColor: "#649B42",
    "&:hover": {
      backgroundColor: "#649B42",
    },
  },
  add_button: {
    background: "#649B42",
    textTransform: "none",
    color: "#FFFFFF",
    marginRight: "15px",
    padding: "8px 15px",
    "&:hover": {
      background: "#649B42",
    },
  },
  clear_button: {
    padding: "6px",
    background: "#D3710F0D",
    border: "1px solid #D3710F",
    color: "#D3710F",
    "&:hover": {
      background: "#D3710F0D",
    },
  },
  search_btn: {
    // padding: "6px 15px",
    width: "auto",
    background: "#366e93",
    color: "white",
    "&:hover": {
      background: "#366e93",
    },
    // marginTop: "33px",
  },
}));

export function EditUserProfile() {
  const classes = useStyles();
  const placeRef1 = useRef();
  const contentRef = useRef(null);
  let [lat, setLat] = useState("");
  let [longi, setLongi] = useState("");
  const history = useHistory();
  const [img, setImg] = useState();
  const [spinner, setSpinner] = useState(false);
  let [uploadingFile, setUploadingFile] = useState("");
  var Service = global.service;
  // let ownerdata = JSON.parse(sessionStorage.getItem('profile'))
  let [ownerdata, setOwnerData] = useState();
  let validateFields = new ValidateFields();

  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };

  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  let formFields = {
    name: {
      name: "name",
      label: "Name",
      topLabel: true,
      value: "",
      placeholder: "Enter your name",
      errorMsg: "Please enter your name",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      // min: 0,
    },
    email: {
      name: "email",
      label: "Email",
      value: "",
      placeholder: "Enter your email",
      errorMsg: "Please enter email",
      validPattern: "EMAIL",
      validationRequired: true,
      topLabel: true,
      isValid: true,
      // min: 0,
    },
    address: {
      name: "address",
      label: "Address",
      value: "",
      validationRequired: true,
      isValid: true,
      lat: null,
      lng: null,
      topLabel: true,
      validPattern: "SPECIAL_CHARS_DESC",
      errorMsg: "Please select address",
      placeholder: "Enter Location",
      completeValue: "",
    },
    mobileNo: {
      name: "mobileNo",
      label: "Mobile Number",
      maxLength: 10,
      topLabel: true,
      validPattern: "NUMERIC",
      validationRequired: true,
      errorMsg: "Please enter mobile number",
      isValid: true,
      placeholder: "Enter mobile number",
      value: "",
    },
    gstinNo: {
      name: "gstinNo",
      label: "GSTIN Number",
      topLabel: true,
      value: "",
      placeholder: "GSTIN Number",
      errorMsg: "Please enter GSTIN number",
      // validPattern: "SPECIAL_CHARS_DESC",
      // validationRequired: true,
      isValid: true,
      // min: 0,
      maxLength: 15,
    },
  };
  let [formData, setFormData] = useState(_.cloneDeep(formFields));
  const closeAlertMsg = () => {
    let error = _.cloneDeep(alert);
    error.open = false;
    error.severity = "";
    error.message = "";
    setAlertData(error);
    // window.location.reload();
  };
  const [countryCode, setCountryCode] = useState({
    countryCode: "in",
    dialCode: "91",
    name: "",
  });
  const ImageChangeHandler = () => {
    fileInputRef.current.click();
  };
    const encryptData = (data, secretKey) => {
      return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    };
    
    // Function to store encrypted data in sessionStorage
    const storeData = (key, data, secretKey) => {
      const encryptedData = encryptData(data, secretKey);
      sessionStorage.setItem(key, encryptedData);
    };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setImg(URL.createObjectURL(selectedFile));
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("id", ownerdata.id);
    Service.updateOwnerProfilePic(formData).then((response1) => {
      if (response1.status == true) {
        Service.getFleetOwnerProfile().then((res) => {
          if (res.status) {
            // global.session.set("profile", res.profile);
            storeData("profile", res.profile,"my_profile")
            setAlertData({
              open: true,
              message: "your profile updated successfully",
              severity: "success",
            });
            window.location.reload();
          }
        });
      } else {
        setAlertData({
          open: true,
          message: response1.message,
          severity: "error",
        });
        setSpinner(false);
      }
    });
  };

  const phoneChangeHandler = (country, value, name) => {
    let newFields = _.cloneDeep(formData);
    let newCountryCode = _.cloneDeep(countryCode);
    newCountryCode = {
      countryCode: country.countryCode,
      dialCode: country.dialCode,
      name: country.name,
    };
    newFields[name]["value"] = value;

    if (value.length > 2 && newFields[name]["validationRequired"]) {
      newFields[name]["isValid"] = true;
    }
    setCountryCode(newCountryCode);
    setFormData(newFields);
  };
  const handleChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setUploadingFile(uploadedFile);
      setFile(URL.createObjectURL(uploadedFile));
    }
  };
  const setLocationHandler = (name, val, lat, lng) => {
    setLat(lat);
    setLongi(lng);
    let newLocFields = _.cloneDeep(formData);
    if (val) {
      newLocFields[name]["value"] = val;
      newLocFields[name]["isValid"] = true;
      setFormData(newLocFields);
    }
  };
  const locFieldsHandler = (value, name) => {
    let newLocFields = _.cloneDeep(formData);
    newLocFields[name]["value"] = value;
    setFormData(newLocFields);
  };

  const inputChangeHandler = (value, name) => {
    console.log(value, name);
    setFormData((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        value: value,
        isValid: true,
      },
    }));
  };

  useEffect(() => {
    let promise1 = Service.getOwnerDetails();
    Promise.all([promise1]).then(async (response) => {
      if (response[0].status) {
        setOwnerData(response[0].data[0]);
        let newFields = _.cloneDeep(formData);
        newFields.name.value = response[0].data[0].name;
        newFields.email.value = response[0].data[0].email;
        newFields.mobileNo.value =
          response[0].data[0].country_code.replace("+", "") +
          response[0].data[0].mobile;
        newFields.gstinNo.value = response[0].data[0].gstin;
        newFields.address.value = response[0].data[0].address;

        placeRef1.current.setPlace({
          label: response[0].data[0].address,
          value: {
            description: response[0].data[0].address,
          },
        });
        setImg(
          response[0].data[0].profile != "null"
            ? response[0].data[0].profile_pic
            : "https://app.suvegafleet.com/newtheme/img/image_placeholder.jpg"
        );
        setFormData(newFields);
        setLat(response[0].data[0].lat);
        setLongi(response[0].data[0].longi);
      }
    });
  }, []);

  const updateProfileDetails = async () => {
    const updateProfile = {
      name: formData.name.value,
      email: formData.email.value,
      mobile: formData.mobileNo.value.replace(countryCode.dialCode, ""),
      address: formData.address.value,
      gstin: formData.gstinNo.value,
      lat: lat,
      longi: longi,
      countryCode: `+${countryCode.dialCode}`,
    };

    let formDataStatus = await validateFields.validateFieldsData(formData);
    if (
      formData.mobileNo.value.length != 12 &&
      formData["mobileNo"]["validationRequired"] &&
      countryCode.dialCode == "91"
    ) {
      formData.mobileNo.isValid = false;
      formData.mobileNo.errorMsg = "Please enter your 10 digits mobile number";
      setFormData(formData);
    }

    if (formDataStatus.status && formData.mobileNo.isValid != false) {
      let promise2 = Service.updateProfileDetails(updateProfile);
      Promise.all([promise2]).then(async (response) => {
        if (response[0].status) {
          Service.getFleetOwnerProfile().then((res) =>{
            if(res.status){
              // global.session.set("profile", res.profile);ss
              storeData("profile", res.profile,"my_profile")
              setAlertData({
                open: true,
                message: response[0].message,
                severity: "success",
              });
              window.location.reload()
            }
          
          })
        } else {
          setAlertData({
            open: true,
            message: response[0].message,
            severity: "error",
          });
        }
      });
    } else {
      let newFields = _.cloneDeep(formData);
      let erroredFields = Object.keys(formDataStatus.data).filter(
        (key, i) => formDataStatus.data[key].isValid === false
      );
      newFields = formDataStatus.data;
      setFormData(newFields);
    }
  };

  return (
    <>
      <div>
        {/* <div
          style={{ borderWidth: "0px 0px 0px 1px", borderBottomColor: "green" }}
        >
          {contextHolder}
        </div> */}
        <Header />
        {alertData.open ? (
          <div className={classes.alertBox}>
            <AlertMessage
              severity={alertData.severity}
              message={alertData.message}
              closeAlert={closeAlert}
            />
          </div>
        ) : null}
        <div className="main_container">
          <div className="header_box">
            <Typography className="header_text">
              {/* <ArrowBack
                className="arrow"
                onClick={() => {
                  history.push({
                    pathname: "./myvehicle",
                  });
                }}
              /> */}
              Update Profile
            </Typography>
          </div>
          <Grid container spacing={2} className="details_container_content">
            {/* <div style={{display:'flex'}}> */}
            <Grid item xs={9} className={"custom_select"}>
              <Grid container className="details_container_content" spacing={3}>
                <Grid
                  item
                  xs={5.5}
                  className={"custom_select"}
                  style={{ marginLeft: "15px" }}
                >
                  <Fields.InputField
                    fieldData={formData.name}
                    inputChangeHandler={(value, name) =>
                      inputChangeHandler(value, name)
                    }
                    variant="outlined"
                    // disabled={fields.vehicleRegistrationNumber.isDisabled}
                  />
                </Grid>
                <Grid item xs={5.5} className={"custom_select"}>
                  <Fields.InputField
                    fieldData={formData.email}
                    inputChangeHandler={(value, name) =>
                      inputChangeHandler(value, name)
                    }
                    variant="outlined"
                    // disabled={fields.vehicleType.isDisabled}
                  />
                </Grid>
              </Grid>
              <Grid container className="details_container_content" spacing={3}>
                <Grid
                  item
                  xs={5.5}
                  className={"custom_select"}
                  style={{ marginLeft: "15px" }}
                >
                  <Fields.PhoneNumberField
                    fieldData={formData.mobileNo}
                    variant="outlined"
                    phoneChangeHandler={phoneChangeHandler}
                    defaultCountry={countryCode}
                  />
                </Grid>
                <Grid item xs={5.5} className={"custom_select"}>
                  <Fields.PlaceSearchField
                    fieldData={formData.address}
                    variant="outlined"
                    setLocationHandler={setLocationHandler}
                    emptyLocHandler={locFieldsHandler}
                    ref={placeRef1}
                  />
                </Grid>
              </Grid>
              <Grid container className="details_container_content" spacing={3}>
                <Grid
                  item
                  xs={5.5}
                  className={"custom_select"}
                  style={{ marginLeft: "15px" }}
                >
                  <Fields.InputField
                    fieldData={formData.gstinNo}
                    inputChangeHandler={(value, name) =>
                      inputChangeHandler(value, name)
                    }
                    variant="outlined"
                    // disabled={fields.vehicleRegistrationNumber.isDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3} className={"custom_select"} marginTop={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    img && img
                      ? img
                      : "https://app.suvegafleet.com/newtheme/img/image_placeholder.jpg"
                  }
                  style={{
                    height: "220px",
                    width: "220px",
                    borderRadius: "8px",
                    marginTop: "10px",
                  }}
                  alt="Preview"
                />
                {/* <button style={{ marginTop: 10, height: 45, width: 90 }}>
                      change
                    </button> */}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <div style={{ marginRight: "22px", marginTop: 10 }}>
                  <Button
                    // className={classes.export_button}
                    style={{
                      marginLeft: "25px",
                      padding: "8px 15px",
                      background: "#366E93",
                      textTransform: "none",
                      color: "#FFF",
                      "&:hover": {
                        background: "#366E93",
                      },
                    }}
                    onClick={ImageChangeHandler}
                  >
                    {img && img ? "Change Profile" : "Upload Profile"}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              container
              spacing={3}
              className="details_container_content"
              marginTop={5}
            >
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <div style={{ display: "flex" }}>
                  <Button
                    className="cancel_button"
                    style={{ marginRight: "15px" }}
                    onClick={() => {
                      // window.location.reload();
                      // history.push("./dashboard")
                      history.goBack()
                    }}
                  >
                    Cancel
                  </Button>
                  {formData.name && formData.email ? (
                    <Button
                      className="save_button"
                      onClick={() => {
                        updateProfileDetails();
                      }}
                      disabled={spinner ? true : false}
                      startIcon={
                        spinner ? (
                          <CircularProgress size={20} color={"#FFF"} />
                        ) : null
                      }
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      className="save_button"
                      onClick={() => {
                        // updateProfileDetails()
                      }}
                      disabled={true}
                      startIcon={
                        spinner ? (
                          <CircularProgress size={20} color={"#FFF"} />
                        ) : null
                      }
                    >
                      Update
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
